import './App.css';
import './Mobile.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; 
import { useState } from 'react';
import Minter from './Minter.js';
import Dev from './Dev.js';
import Data from './Data.js';
import Prov from "./Prov.js";
import MinterTest from "./MinterTest.js";
import IDSystem from './IDSystem';
function App() {
  return (
    <div>
    <Router>
      <Switch> {/* Home, Data, Dev */}
            {/* 
                Home imports Minter, Minter imports About 
                Data imports Provenance
                Dev imports DevManifesto, contains Divi's Roadmap
             */}
            <Route exact path="/"> 
             <div className="App">
                <Minter /> {/* */}
              </div>
            </Route>
            <Route exact path="/home"> 
             <div className="App">
               <Minter />   
              </div>
            </Route>
            
            <Route path="/data"> 
              <Data /> {/* */}
            </Route>               
            <Route path="/dev"> 
              <Dev /> {/* */}
            </Route>            
            
            <Route path="/prov"> 
              <Prov /> {/* */}
            </Route>            
          </Switch> 
          
    </Router>
      <div className="App">
      </div>
      </div> 
    
    
    

  );
}

export default App;
