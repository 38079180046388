import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mint,
  mintCubes,
  buyTicket,
  //getFire,
  functionGetCubes
} from "./functions.js"; 

import Navbar from "./Navbar.js"
import About from "./About.js"
import logo from './mystery_cubes_collection.png';
import MysteryClub from "./MysteryClub.js";

///////////////////////////////////////START CONNECT WALLET [do not change]/////////////////////////////////////
const Minter = (props) => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState(""); //error reporting 
  const [info, setInfo] = useState(" ");  // error reporting 
  const [mintQuantity, setMintQuantity] = useState("1"); 
  const [myTickets, setMyTickets] = useState("0"); //unused 
  const [fireStatus, setFire] = useState("");
  //const [cubeStatus, setCubeStatus] = useState(""); //getCubes
  //let testCube = "henlo dere"; //!!!!!!!!!!!!!! 

  useEffect(async () => { ///////////////////////////////////////useEffect...this applies to more than just wallet right?
    const { address, status } = await getCurrentWalletConnected();

    setWallet(address);
    setStatus(status);
    setInfo(info); 
    addWalletListener();

    setMintQuantity(mintQuantity); 
    //setCubeStatus(testCube);
  }, []);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("Ready.");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
    if (window.ethereum.chainId != "0x1") {  
      console.log('You are not on mainnet');  
      setInfo("☠️YOU ARE NOT ON MAINNET  ");

    }
    else if (window.ethereum.chainId == "0x1") {  
      setInfo(" ");

    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

 ///////////////////////////////////////END CONNECT WALLET/////////////////////////////////////
{/* 
  const fireGetInfo = async () => {                           
      await getBalance().then(function(result){
      setMyTickets(result); //usually set JSON.stringify(result)
    });
  }; 
 
  function firegetCubes(tokenidinput) {                           
    functionGetCubes(tokenidinput).then(function(result){ //function that calls web3 call 
       testCube = JSON.stringify(result)//JSON.stringify(result); 
    });
  };
 */}
  const fireMintCubes = async () => {          
    await mintCubes(mintQuantity);  
   };
 
  const fireBuyTicket = async () => {                            
    buyTicket(); 
   };  
 
  const link = "https://etherscan.io/address/0xbb455576298a2505797f5c477d380aa62dd916a8#writeContract";
  const linkcontract = "https://opensea.io/collection/a-mystery-cubes-club";
  const github = "https://github.com/dev-divi/A_Mystery_Cubes_Club/blob/main/AMysteryCubesClub_Contract/AMysteryCubesClub.sol"; 

  return (
    
    <div className="Minter" >

      <div className="InsideMinter">
        <button id="walletButton" onClick={connectWalletPressed}>
          {walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(35, 38)
          ) : (
            <span>Connect Wallet</span>
          )}
        </button>
        <br></br>
          
        <h1 id="title">{/*The ... Eternal... Infinity...Possibilities .. Of..   */}A Mystery Cubes Club!</h1>
         <div id="mobilenft">
         <iframe id="nftmain" src="https://ipfs.io/ipfs/QmdidCBS7Nd8pzFbifHGRAsuRzEXSE8tgq6WKrE9Fs1R2q"> </iframe> 
        </div>          
            {/*<p id="launchdate"> 1 2 1  1 2 1 </p> <br /> <br /> */}
          <span className="MM-link"> {status}  <br />  {info}  </span>
             <br />
            {/*<
             <button id="mintButton" onClick={firegetCubes()}> get fire test! </button>  

          <button id="mintButton" onClick={firegetCubes(0)}> get fire! </button>  
          <p id="status" > 
             Name: {testCube} <br /> 
             Quote: {cubeStatus} <br /> 
             Art: {testCube[2]} <br /> 
          </p> 

            */} 
        <div id="ticketandminter">
        <button id="buttonspecial"  onClick={fireBuyTicket}> T I C K E T </button>  <br /> 

        <button id="buttonmintandredeem"> 
        <button id="buttonspecial"> 
        <form>
        <p id="quantityhead">redeem:</p>
        <input
          id="cubequantityinputbox"
          type="number"
          placeholder="1"
          onChange={(event) => setMintQuantity(event.target.value)}
        />
      </form>
        </button> 
  
        <button id="buttonspecial" onClick={fireMintCubes}> M I N T </button> 
        </button>  
        <br /> <br /> <br / > 
        <a id="link" href={link}> Etherscan</a><br /> <br /> 
        <a id="link" href={linkcontract}> Opensea</a> <br /> <br /> 
        <a id="link" href={github}> Github - Contract </a> <br /> 

        <br /> 
         
        <br />     

</div> 
    <div id="quantitydiv">
      </div> 
 
        <div id="minternavbar">
          
          <Navbar /> 
          </div>
        <About />
         
      </div>


    </div>

    
  );
};

export default Minter;
