import NameList from "./NameList";
const MysteryClub = () => {
    return ( <div id="mysteryclub">
        <div id="roadmap">
        <div id="terminaldiv_idlist">
            CUBE ID LIST <br /> <br /> 
        <NameList / > 
        </div>
        </div>
    </div> );
}
 
export default MysteryClub;