const AboutManifesto = () => {
    return ( 
        <div id="manifesto">
                <h2 id="manifestoheader"> <span id="hacker"> Dev Divi</span>  <br /> Cubes Network Manifesto - <span id="hacker"> 2021 </span></h2> 
<p id="manifestocontent"> 
I dream of mystery cubes being a cornerstone of a metaverse with integration into possibilities we haven't seen yet.<br /> 
The technology of NFTs on-chain is exciting, but to be honest,
what drives me is not what is right now, but rather a dream I feel I have been handed.<br /> 
<br /> <br /> 
In this dream is a desire to build into our future.<br /> 
I desire to build interactive art that has utility, and artistic forms that have function.<br /> 
I desire for my interfaces to feel fluid, <br />like magic.<br /> 
<br /> <br /> 
The standards of interaction in our world now will impact the generic interfacing that has yet to be.<br /> 
Many exciting things in this future turned out to be mundane due to a lack of inspiration to accompany innovation.<br /> 
I am a creative, and I have no such lack.<br /> 
<br /> <br /> 
In a short time, my creations will enter virtual, augmented, and extended reality.<br /> 
It is my desire that when they do, they will be capable of interactivity with actual use cases.<br /> 
<br /> <br />
A little bit about me, I believe in the possibility of possibility. In this digital space I have begun to discover myself. In this I have discovered that I possess an affinity for innovation. <br/>
This is displayed in the public wargame, <br/> 'Hero or Villain', a project that is the first of its kind and shows what is possible with a truly decentralized and public Blockchain system. 
<br /> <br /> 

{/* <!--
There is a path I am running on right now that has a trajectory that will go farther than I can anticipate. 
Right now, I am aware of technologies and possibilities that I did not know existed before recently. 
I know that there is an endpoint in this direction at the heart of this technology, and I know that future is being pulled towards me, without my own understanding as to why.
*/}

I am certain that going in this direction, I will be building interesting projects people will love along the way, and that is really what I'm in this for and what continues to excite and empower me. <br /> 
I am really happy to be here for as long as that is.
<br /> <br /> 

Revolutionary projects are the name of the game. <br /> 
Thank you for reading here today. - <span id="hacker"> Divi 🧞 </span> 

</p>
<br /> 
        </div> 
    );
}
 
export default AboutManifesto;