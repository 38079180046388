import Provenance from "./Provenance.js"
import ProvenanceList from "./ProvenanceList";
import Navbar from "./Navbar.js"

const Prov = () => {
    return ( 
        <div id="ProvHead"> 
                <br /> 
                <Navbar />
                <br /> <br /> 
                <div id="provenance"> 
                    <h2>  P R O V E N A N C E </h2> 
                    
                    <Provenance /> 
                    <br /><br />
                    <ProvenanceList /> 
                    
                </div>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        </div>
     );
}
 
export default Prov;