

const Provenance = () => {
    return ( 
        <div id="provenance">
           [special data has yet to be updated] 
            <div id="masterdirs"> 
                <br/> 
                <br/> 
                <p id="ipfs_desc"> Master Data Directory IPFS Hash: <a class="hash" href="https://ipfs.io/ipfs/QmakBGaVtEfqTCWZF8AbNBmEqs2TSDKPzsHUKT476ywDk1">QmakBGaVtEfqTCWZF8AbNBmEqs2TSDKPzsHUKT476ywDk1</a> <br /> 
                </p>
                <br/> 
                <p id="ipfs_desc"> Master Images Directory IPFS Hash: <a class="hash" href="https://ipfs.io/ipfs/QmWZvd3VCY5FbbBzWvVEj1M9uFGfdcpSAZNpBPmuMxp1HT">QmWZvd3VCY5FbbBzWvVEj1M9uFGfdcpSAZNpBPmuMxp1HT</a> <br /> 
                </p><br/> 
                <p id="ipfs_desc"> Master Assets Directory IPFS Hash: <a class="hash" href="https://ipfs.io/ipfs/QmYp9Fr93w88cZyEaCgFqb8tph4e7JpZAoUieZkX48CfVr">QmYp9Fr93w88cZyEaCgFqb8tph4e7JpZAoUieZkX48CfVr</a> <br /> 
                </p><br/> 
                <p id="ipfs_desc"> Master Code Directory IPFS Hash: <a class="hash" href="https://ipfs.io/ipfs/QmZyrsfBdpttx4WvtCJ3HwrRvSWcjjgp8aph1WpKEas73k">QmZyrsfBdpttx4WvtCJ3HwrRvSWcjjgp8aph1WpKEas73k</a><br /> 
                </p><br/> 
                <p id="ipfs_desc"> Master Metadata Directory IPFS Hash: <a class="hash" href="https://ipfs.io/ipfs/QmQbUXfsW4ofbYGVYnPYkaLKg5TAyfQucs4GhHYcFDFQbW">QmQbUXfsW4ofbYGVYnPYkaLKg5TAyfQucs4GhHYcFDFQbW</a> <br /> 
                </p>
                <br/> 
                <br/> 
                <br/> 
                <br/> 
                <p id="ipfs_desc"> Metadata Array Hash: <a class="hash" href="https://ipfs.io/ipfs/QmcrcNMoVU9v22sXGgddq7S7FE2CwWG8X5pfZS6R1g4C4T">QmcrcNMoVU9v22sXGgddq7S7FE2CwWG8X5pfZS6R1g4C4T</a> <br /> 
                </p>  <br/> 
                <p id="ipfs_desc"> Metadata JSON Hash: <a class="hash" href="https://ipfs.io/ipfs/QmYZXdb5YSvDQ3rju9iW4MdTWbqE4RZtLC5ztWw3AAo8py">QmYZXdb5YSvDQ3rju9iW4MdTWbqE4RZtLC5ztWw3AAo8py</a> <br />  
                </p>  <br/> 
                <p id="ipfs_desc"> Master Directory JSON Hash: <a class="hash" href="https://ipfs.io/ipfs/QmSuXanfVn1AwXLfAG6eNwAWt7CxBggLU8fQZUDyxazivo">QmSuXanfVn1AwXLfAG6eNwAWt7CxBggLU8fQZUDyxazivo</a> <br /> 
                </p>   <br/> 
                <br/> 
                <br/> 
                <p id="ipfs_desc_small">PNG Hash: <br /> <a class="hash_alt" href="QmNjutsJE5Udg3vd7tEKbXp9b4dg1zSyAjTEodvtixjouB">QmNjutsJE5Udg3vd7tEKbXp9b4dg1zSyAjTEodvtixjouB</a><br /> 
                </p>
                <br/> 
                <p id="ipfs_desc_small">Cube Hash: <br /><a class="hash_alt" href="https://ipfs.io/ipfs/QmUEebbXD1NW6vyhHMF5YgTvSqVWSNJ2n41PcRuKxEDxkV?filename=Mystery_Cube.html">QmUEebbXD1NW6vyhHMF5YgTvSqVWSNJ2n41PcRuKxEDxkV</a><br /> 
                </p>
            </div> 
            <br/> 
            <br/> 
            <br/> 
            <div> 

            </div>
          </div> 
     );
}
 
export default Provenance;
