import Navbar from "./Navbar.js"
import DevManifesto from "./DevManifesto.js"

const Dev = () => {
    return (       
        <div id="Dev">
            <br /> 
            <Navbar /> 
            <br /> <br /> 
            <div id="manifestoroadmap"> 
                <DevManifesto />
           </div>          
           <div id ="manifestoroadmap"> {/* change to roadmap */}
           
           <h2 id="diviroadmap">  <span id="hacker"> Divi's Roadmap </span> </h2> 
           <div id="roadmap_content">
           - Prototype - Crystal Cubes 
                             <br /><br /> 
           - 412 - A Mystery Cubes Club!  // 12/11/21 
                             <br /><br />  
                             
           - Cube Holder Chat // 12/30/21 
                             <br /><br />  
           - Club Badge System // 01/11/21 
                             <br /><br />  
           - Quote Wall Creation 
                             <br /><br />  
                             
           - Hero Or Villain Deploy <br /> w/ Cubes Integration 
                             <br /><br />
           - Project Codename G01
           <br /><br />
           - Project Codename WAR
           <br /><br />
           - Project Codename Interfaces
           <br /><br />
           - Project Codename Tritium 
           <br /><br />           - 909 - Infinity Cubes 
                             <br /><br />
           </div>
           <br /><br /><br />
          </div>
        </div> 

        
     );
}
 
export default Dev; 

