import Mobile from './Mobile.css'

const Navbar = () => {  {/* Home, Data, Dev */}
    return (  
        <nav className="navbar">
            <div className="links" id="linksdiv">
                <a href="/home" className="navlink" style= {{}}> Home </a> 
                <a href="/data" className="navlink" style= {{}}> Data </a> 
                <a href="/dev" className="navlink" style= {{}}> Dev </a>   
                <a href="/prov" id="provbutton" className="navlink" style= {{}}> Prov </a>  

            </div>
        </nav>
    );
}
 
export default Navbar;