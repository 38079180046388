import Navbar from "./Navbar.js"

import special from './special_cubes_gallery_02.png';
import mysc from './Mystery_Cube_345.png';
import gold from './Golden_Cube_345.png';

const Data = () => {
    return (       
        <div id="Data">
              <br/> 
              <div id="datanavbar"> 
              <Navbar /> 
              </div> 
              <br /> 
              <br /> 
              <div id="roadmap">
                  
                <h2 id="community"> MYSTERY CUBES </h2> 
                <p>These 362 crystalized diamond cubes <br />are for diamond hands. </p> <br />
                <img src={mysc} className="App-logo" alt="logo" id="logomystery" />

                <h2 id="community"> GOLDEN CUBES </h2> 
                <p>42 rares await their fortunate fated holders.<br /> </p> <br />
                <img src={gold} className="App-logo" alt="logo" id="logomystery" />

                
                <h2 id="community"> 1/1s </h2> 
                <p> 8 One of a kinds. </p><br />
                <img src={special} className="App-logo" alt="logo" id="logomystery" />
                <br />  
                <h2 id="community"> SPECS </h2> 
                <p>The Cubes are stored as ERC-721 tokens on the Ethereum blockchain and hosted on IPFS. Purchasing a Cube costs 0.025 ETH. </p>                
                <br />  
                <h2 id="community"> DATA </h2> 
                <p>The Cubes come equipped with Name, Quote, and Artwork functions, allowing their owners to personalize their data on-chain. </p>
                <br /> 
                <h2 id="community"> METAVERSE READY </h2>
                <p> 
                    The Cubes come future proofed with a storage variable that can be set to the address of any platform. 
                    Perhaps one day we will have addresses that lead to our own digitalized worlds? 
                </p>
                <br /> 
            <div id="roadmap">
                <h2 id="community"> ID System Info </h2> 
                <p>
                On chain Name, Quote, and Artwork functions are built into the cubes. 
                This data is visible in the ID System on this website, as well as directly on Etherscan. 
                <br /> <br /> 
                As this data is public on the blockchain, it is possible for anyone to build interfaces that 
                visualize or utilize this data as they prefer!  
                </p>
 
                <br /> 
                <br />   
            </div>                 


            </div>
            <br /><br /><br /><br />  {/* 4 breaks */}
            <h1> Cube Gallery </h1> <br /> <br /> <br /> 

            Mystery Cube <br /> 
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmdidCBS7Nd8pzFbifHGRAsuRzEXSE8tgq6WKrE9Fs1R2q"> </iframe> <br /> 

            Golden Cube  
                
                  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmY4SbKJsAHPQ8fZMKFdhnUjUHsDjH8EmgkeM6RD5xr1rp"> </iframe> 



                <br />
                S01  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmaMyvPXNcmbJ7yQpoaKxreDLG1zE32c9jmyCdx5mix7nw"> </iframe> 

            <br /> 
                S02  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmSHLjNEKfosKDu838R6vvuWjcncjYPxLgvd3C4Nt28SuH"> </iframe> 
            <br />   S03  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmYSzMQD5pxiRnDTmsD9AkpiK1qr6mbPxZvfrtGvzgtKQV"> </iframe> 
                <br />
                S04  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmbpVTLrdZ1izMDXeWHxwxVCihPRz9ETU9EW5TBm4Zkbrp"> </iframe> 
                <br />
                S05  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmfKHWzGxjocD9JAXV6KfxhQVingg3WoFyNT5cxtY2NA3G"> </iframe> 
                <br />
                S06  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmPrd42pQpU5pczYX7iCqJdD1v9feMXXbzaRApp3NZNbya"> </iframe> 
                <br />
                S07  <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmUSVUUZr8tNZQX43eRtJp9nxDikL9nvxbgQVTva7SvPBH"> </iframe> 
                <br />
                S08 <br />
            <iframe id="nftmain" src="https://ipfs.io/ipfs/QmceMrnosamZEvdm7PK1DNkvYg751rSyJD63GxqAa7tSab"> </iframe> 
            <br /> 
                <br />



        </div> 
     );
}
 
export default Data;