//import { pinJSONToIPFS } from "./util/pinata.js";

require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("./MYSC.json");
const contractAddress = "0xbb455576298a2505797F5c477D380Aa62Dd916a8";
//ropsten 0x89527701b00554E03157fEB8e1e3a1d4B523e960
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const defaultquantity = 1; 

///////////////////////////////////////START CONNECT WALLET [do not change]/////////////////////////////////////
export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: " ",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Account Connected",
        };
      } else {
        return {
          address: "",
          status: "🦊 Connect to Metamask using the top button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" id="mmwhitelink" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
///////////////////////////////////////END CONNECT WALLET/////////////////////////////////////
async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}
export const buyTicket = async () => {  
  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
  if (window.ethereum.chainId != "0x1") {  
    console.log('You are not on mainnet');  
   
    return {
      status: "😥You are not on mainnet  ",
    };
  }
else { 
    const get = window.contract.methods.buy_Ticket().send({  
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        value: 25000000000000000
      });
 } 
}

export const mintCubes = async (quantity) => {  
  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
  if (window.ethereum.chainId != "0x1") {  
    console.log('You are not on mainnet');  
  }
  else {
    const get = window.contract.methods.mint_Cubes(quantity).send({  
        to: contractAddress,
        from: window.ethereum.selectedAddress,
        value: 0
  });
} 
}
 {/* 
export const getFire = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
  const get = window.contract.methods.get_My_Ticket_Balance().call(); 
  return get
} // ( the .then is when you call it) 

*/} 

export const functionGetCubes = async (tokenidinput) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
  const get = window.contract.methods.getCubeData(tokenidinput).call();  //temporarily pass in tokenid0 
  return get
} // ( the .then is when you call it) 
 



{/*   
export const functionGetCubes = async (tokenidinput) => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
  const get = window.contract.methods.getCubeData(tokenidinput).call();  //temporarily pass in tokenid0 
  return get
} // ( the .then is when you call it) 
  {/*   
 
*/} 
































//here we are. 
//now of course, 
//export const getStatus = async () => {                        
//  window.contract = await new web3.eth.Contract(contractABI, contractAddress); 

//  const transactionParameters = {
//    to: contractAddress, // Required except during contract publications.
//    from: window.ethereum.selectedAddress, // must match user's active address.
//    data: window.contract.methods
//      .getStatus()    
//      .call(),
//  };

//};
    
//export const mintNFT = async (url, name, description) => {                      
//  if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
//    return {
//      success: false,
//      status: "❗Please make sure all fields are completed before minting.",
//    };
//  }

  //make metadata
//  const metadata = new Object();
//  metadata.name = name;
//  metadata.image = url;
//  metadata.description = description;

//  const pinataResponse = await pinJSONToIPFS(metadata);
//  if (!pinataResponse.success) {
//    return {
//      success: false,
//      status: "😢 Something went wrong while uploading your tokenURI.",
//    };
//  }
//  const tokenURI = pinataResponse.pinataUrl;

//  window.contract = await new web3.eth.Contract(contractABI, contractAddress); //definitely important 

//  const transactionParameters = {
//    to: contractAddress, // Required except during contract publications.
//    from: window.ethereum.selectedAddress, // must match user's active address.
//    data: window.contract.methods
//      .mintNFT(window.ethereum.selectedAddress, tokenURI)
//      .encodeABI(),
//  };

//  try {
//    const txHash = await window.ethereum.request({
//      method: "eth_sendTransaction",
//      params: [transactionParameters],
//    });
//    return {
//      success: true,
//      status:
//        "✅ Check out your transaction on Etherscan: https://ropsten.etherscan.io/tx/" +
//        txHash,
//    };
//  } catch (error) {
//    return {
//      success: false,
//      status: "😥 Something went wrong: " + error.message,
//    };
//  }
//};
