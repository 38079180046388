
import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  mint,
  mintCubes,
  buyTicket,
  //getFire,
  functionGetCubes
} from "./functions.js"; 

  const getCubeData = ""; // getCubeData() returns Name, Quote, Art 
  const tokenid = "214"
  const member_Name = "0xdivi";
  const cube_Quote = ` "Every man is a golden link in the chain of my good." - The Game of Life ` ; //retain backticks  
  const art_Path = "https://i.imgur.com/dosSAr7.png"; 
  const metaverse_Path = ""; 
  let array = [ 
    'Abracadabra',
    'Adaptability',
    'Continuity',
    'Fancy',
    'Idea',
    'Artist',
    'Happiness',
    'Prodigy',
    'Expression',
    'Player', //game 
    'Relaxation',
    'Excellence',
    'Communication',
    'Quality',
    'Invincibility',
    'Bountiful',
    'Priceless',
    'Understanding',
    'Adorable',
    'Refresh',
    'Conquer',
    'Intensity',
    'Intention',
    'Awesomeness',
    'Practicality',
    'Encouragement',
    'Calming',
    'Exemplary',
    'Appreciation',
    'Precision',
    'Gladness',
    'Discretion',
    'Eudaimonia',
    'Sensational',
    'Awakening',
    'Order',
    'Glory',
    'Alluring',
    'Justice',
    'Endurance',
    'Brightness',
    'Love',
    'Commitment',
    'Curious',
    'Fun',
    'Future',
    'Absolute',
    'Allowing',
    'Romance',
    'Loyalty',
    'Equitable',
    'Gift',
    'Enthusiasm',
    'Empathy',
    'Confidence',
    'Generativity',
    'Exhilaration',
    'Plentiful',
    'Family',
    'Productivity',
    'Creation',
    'Limitless',
    'Remarkable',
    'Respect',
    'Inclusion',
    'Refinement',
    'Worthiness',
    'Value',
    'Viability',
    'Triumph',
    'Decisiveness',
    'Attainment',
    'Godly',
    'Fair',
    'Leader',
    'Fascination',
    'Imagination',
    'Revelation',
    'Intimacy',
    'Wonder',
    'Readiness',
    'Affirmative',
    'Hero',
    'Purpose',
    'Intrigue',
    'Influence',
    'Capability',
    'Independence',
    'Recommendation',
    'Nature',
    'Evolution',
    'Mercy',
    'Versatility',
    'Invention',
    'Persistence',
    'Abracadabra',  // uh oh 
    'Effortlessness',
    'Fortune',
    'Study',
    'Consistency',
    'Joy',
    'Choice',
    'Simplicity',
    'Magnificent',
    'Personality',
    'Courage',
    'Clarity',
    'Grounding ',
    'Flow',
    'Friend',
    'Dearest',
    'Skill',
    'Advancement',
    'Win',              //game 
    'Concentration',
    'Truth',//data
    'Fearless',
    'Heart',
    'Glamour',
    'Divinity',
    'Luxury',
    'Enthrallment',
    'Soul',
    'Accuracy',
    'Hope',
    'Helping',
    'Delightful',
    'Connoisseur',
    'Faith',
    'Fire',
    'Spirit',
    'Victory',
    'Luck',
    'Grace',
    'Enlightenment',
    'Assurance',
    'Amazing',
    'Optimist',
    'Wisdom', //knowledge
    'Determination',
    'Gratitude',
    'Blooming',
    'Paradise',
    'Heaven',
    'Preparement',
    'Presence',
    'Rejuvenation',
    'Industrious',
    'Satisfaction ',
    'Reverence',
    'Abundance', //wealth
    'Learning', //knowledge
    'Elation',
    'Spontaneity',
    'Consolation',
    'Miracle',
    'Competency',
    'Success', // Success
    'Fame',
    'Preservation',
    'Power',
    'Meaning',//knowledge 
    'Fabulous',  // Beauty 
    'Will',
    'Opportunity',  // Success
    'Eternal ',
    'Conservation',
    'Hello',
    'Perfection',  // Beauty 
    'Absorption',  // Alchemist
    'Brilliance',  // Beauty 
    'Great',
    'Cuteness',
    'Clarity',
    'Caring',      //healing
    'Earth',
    'Serenity',
    'Advantageous ',
    'Strength',
    'Deliciousness',
    'Blossoming',
    'Oneness ',
    'Infinity',
    'Covenant',
    'Discernment',
    'Liberty',
    'Kindness',  //healing
    'Forgiveness',   //healing
    'Dependable',  //Value 
    'Humility',  //Virtue  
    'Cozy',
    'Invitation',
    'Companion',  // Game 
    'Good ',
    'Passion',
    'Irresistibility',  // Beauty 
    'Honor',  //Value 
    'Resilience',  //Virtue  
    'Light',
    'Engagement',
    'Enchanted',  // Beauty 
    'Exaltation', // Success
    'Congratulations',   // Success
    'Knowledge',   //knowledge 
    'Inspiration',
    'Enhancement',  // Grow 
    'Peace',  // Spirit 
    'Sacred',
    'Sincerity',
    'Invigoration',
    'Connection',
    'Alignment',
    'Bravery',  //Value 
    'Righteousness',
    'Compatable',
    'Support',
    'Adventurous',
    'Maturity',
    'Heal',  //healing
    'Transformation',
    'Awareness',
    'Qualifier',
    'Comforting',
    'Interesting',
    'Expertise',
    'Elegance',  // Beauty 
    'Rebirth',
    'Closeness',
    'Balance',  // Whole 
    'Security',
    'Integrity',
    'Mind',  // Knowledge 
    'Aloha',
    'Discovery',
    'Exquisite',
    'Charm',
    'All', // Whole 
    'Logic', // Knowledge 
    'Renewal',
    'Ability',
    'Belonging',
    'Responsibility', //Value ?
    'Reason', //knowledge 
    'Collecting',  //wealth 
    'Transparency',
    'Copacetic',
    'Dream', // Creative 
    'Life',  // life ? 
    'Sweetness',
    'Radiance', // light 
    'Longevity',  // life 
    'Desire',  //.... oohh....
    'Openness ',
    'Permanence',
    'Expansion',
    'Mindfulness',
    'Reliability',  //v
    'Water',  // Elemental 
    'Flourishing',
    'Completion',
    'GM',  // 
    'Certainty',  // knowledge 
    'Participation',  //connection 
    'Giving',
    'Purity',
    'Celebration',  // success 
    'Acclaim',  // success 
    'Investment',
    'Revive',  // life 
    'Mighty',
    'Exuberance',
    'Freedom',
    'Astounding',
    'Relationship',  //connection 
    'Innovation',
    'Alive',
    'Illumination',
    'Genuine',
    'Change',
    'Gentleman',
    'Reality',
    'Originality',
    'Cuddle',
    'Salvation',
    'Achievement',
    'Vulnerability',
    'Direction',
    'Dazzling',
    'Astronomical',
    'Comedy',
    'Energy',
    'Incredible',
    'Hospitality',
    'Virtue',
    'Contentment',
    'Efficiency',
    'Perception',
    'Charismatic',
    'Genius',
    'Nourishing',
    'Empowerment',
    'Perspective',
    'Devotion',
    'Do',
    'Prosperity',
    'Exploration',
    'Thankfulness',
    'Authenticity',
    'Talent',
    'Clever',
    'Equanimity',
    'Emulation',
    'Masterful',
    'Vigor',
    'Ingenuity',
    'Appreciation',
    'Ambition',
    'Improvement',
    'Acceptance',
    'Extraordinary',
    'Rapture',
    'Angel',
    'Assertion',
    'Community',
    'Fortitude',
    'Dedication',
    'Stimulation',
    'Stability',
    'Benefit',
    'Clean',
    'Consideration',
    'Flawless',
    'Compassion',
    'Exceptional',
    'Guidance',
    'Goddess',
    'Marvelous',
    'Experience',
    'Diligence',
    'Relief',
    'Impression',
    'Inner',
    'Beloved',
    'Punctuality',
    'Resolution',
    'Inventor ',
    'Trust',
    'Air',
    'Consciousness',
    'Accomplishment',
    'Recognition',
    'Vibrancy',
    'Champion',
    'Admirable',
    'Synergy',
    'Fulfillment',
    'Accountable',
    'Magic',
    'Nurturing',
    'Breathtaking',
    'Agility',
    'Tolerance',
    'Pleasure',
    'Conviction',
    'Enticement',
    'Wholeness',
    'Cool',
    'Human',
    'Benevolence',
    'Holy',
    'Altruism',
    'Reward',
    'Tranquility',
    'Meditation',
    'Cooperation',
    'Feeling',
    'Generosity',
    'God',
    'Growth',
    'Affirmation',
    'Motivation',
    'Focus',
    'Enough',
    'Affectionate',
    'Overflow',
    'Preciousness',
    'Living',
    'Rationality',
    'Dignity',
    'Enjoyment',
    'Cheerfulness',
    'Pride',
    'Mastery',
    'Intuition',
    'Intelligence',
    'Discipline',
    'Blessing',
    'Elevation',
    'Insight',
    'Ageless',
    'Restoration',
    'Equality',
    'Sexy',
    'Progress',
    'Knowing',
    'Beauty',
    'Attraction',
    'Courtesy',
    'Possibility',
    'Honesty',
    'Harmony',
    'Health'
]
const IDSystem = () => {

    const [cubeStatus, setCubeStatus] = useState(""); //getCubes
    const [cubeIDNumber, setCubeIDNumber] = useState(0); //getCubes ID 
    
  useEffect(async () => {  
      setCubeIDNumber(cubeIDNumber); 
    }, []);
  
  function firegetCubes(tokenidinput) {                           
      functionGetCubes(tokenidinput).then(function(result){ //function that calls web3 call  
        setCubeStatus(result);  
      });
    };    
    return ( 
        
        <div id="IDSystem" class="IDSystem"> {/* */}
        <div id="terminaldiv_IDSystem"> 
   
        {/* 
        <p id="status" > 
            Name:  <br /> 
            Quote:  <br /> 
            Art: {cubeStatus[2]} <br /> 
        </p> */}
        Cubes ID System on Ethereum Mainnet <br /> <br /> 
        <span id="hacker2"> 

        <header id=" ">// Welcome to  Mystery Club Main Terminal!</header> <br />
        <p id=" "> //to connect to terminal:   </p>

        <p id=" "> // 1. connect to metamask  </p>

        <p id=" "> // 2. enter token id and press 'retrieve token'  </p>

        </span>
        <form>
        <br /> 
        <p id=" "> Token ID # </p>
        <input
          id="Badgeinput"
          type="number"
          placeholder="0"
          onChange={(event) => setCubeIDNumber(event.target.value)}
        />
      </form>
         <button id="Badgeinput" onClick={() => firegetCubes(cubeIDNumber)}> Retrieve Token </button>  
             
            <div id="flex_idsystem">
                
                 <iframe id="idcube" src="https://ipfs.io/ipfs/QmPvzmFcx3RQckF4nmfXsMEhdSJXuYiSuyXv2Fb3D8v5vv"> </iframe> 
                <div id="stats"> 
                    //_Cube <span id="hacker">{array[cubeIDNumber]}</span>
                    <br /> <br /> 
                    //_Club_ID <span id="hacker"> {cubeIDNumber} </span>
                    <br /> <br /> 
                    <p id="member"> //_Member  <span id="hacker"> {cubeStatus[0]}</span> </p> 
                    <br />  
                    <p id="quote"> //_Quote  <span id="cube_text"> {cubeStatus[1]} </span> </p> 
                    <br /> 
                    <p id="art">//_Art  <span id="artworklink">
                    <a id="hacker" href={cubeStatus[2]}> External Artwork </a></span>  </p> 
                    <br /> 
                    <div id="artwork"><img id="artworkimg" src={cubeStatus[2]}></img> </div>
                </div>  
                
            </div>
            <br /> 
            </div>
            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> 
            <p id="hacker2"> //troubleshooting: <br /> try refreshing browser</p>

        </div>
     );
}
 
export default IDSystem;