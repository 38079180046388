import IDSystem from "./IDSystem.js";
import "./IDSystem.css";
import logocubes from './mystery_cubes_collection.png';

import * as React from 'react'

import WidgetBot from '@widgetbot/react-embed'
import tutorial from './tutorial.PNG';
import MysteryClub from "./MysteryClub.js";

const About = () => {
    return ( 
        
        <div id="About"> 

          <br /> <br /> <br /> <br />  <br /> <br />  <br /> <br /> <br /> <br /> 

          <img src={logocubes} className="App-logo" alt="logo" id="logocubes"  />

          <div id="roadmap">
              {/* 
             <h3 id="community"> launch info </h3> 
            
             <p> 
             SAT 12/11/21 <br /> 
             9:00PM EST <br /> 
             0.025E <br /> 
             362 + 42 Gold + 8 1/1s = 412 <br /> 
             https://AMysteryCubesClub.com

             </p>
            */}
          <div id="terminaldiv"> 
          <h2 id=""> - Welcome to Club Main Terminal (v.1.0.1) -</h2> 
           <a href="/data" id="hacker3" className=" " style= {{}}> - Data - Info, Specs, Gallery </a>  <br /> 
           <a href="/dev" id="hacker3" className=" " style= {{}}> - Dev - Manifesto and Roadmap </a>    <br />
           <a href="/prov" id="hacker3" className=" " style= {{}}> - Prov - Provenance IPFS Data  </a>   <br /> 
           <h2 id="">On This Page -</h2> 
           <a href="#badgedemoref" id="hacker3" className=" " style= {{}}> - Cube ID List  </a>  <br /> 
           <a href="#badgedemoref" id="hacker3" className=" " style= {{}}> - ID System - Live On-Chain  </a>  <br /> 
           <a href="#howtomintref" id="hacker3" className=" " style= {{}}>- How to Mint - Instructions    </a>  <br /> 
           <a href="#discordref" id="hacker3" className=" " style= {{}}> - Discord - Club Chat   </a>  <br /> 
           <a href="#diviref" id="hacker3" className=" " style= {{}}>- Support Bot    </a>  <br /> 
            <br /> 
           <br /> 
            <br />  
            </div>
             {/*
           Quote Wall - Coming Soon <br /> 

         <h2 id="sitemapdiv">Extras -</h2> */} 

           
         </div> <br />  <br /> <br /> <br /> <br /> 
            <MysteryClub />  
            
           <br /><br /><br /><br /> 
           
          
              <br /><br /><br /><br /> 
              <span id="badgedemoref"></span>
              <IDSystem />
              <br /><br /><br /><br />
              <br /><br /><br /><br />
              <br /><br /><br /><br />
                   <div id=" ">
             <br /> <br /> 
             <span id="howtomintref"> </span>
             <h2 id="community">  How to Mint Instructions </h2> 
             <p> 
              1. Connect Wallet <br /> <br /> 
              2. Use the TICKET button to purchase 1 ticket. <br /> <br /> 
              3. Enter the number of tickets acquired in the redeem box and use the MINT button to redeem your Cubes. 
             </p><br /><br /><br />
             <img src={tutorial} className="App-logo" alt="logo" id="tutorial" />
             <br /><br /><br /><br />  
              <br /> <br /><br /> <br />  
             <br /> 

             <br />   
         </div>
{/* 
              <div id="quotesystem"> 
                Quote Wall  
</div> */} 
              <span id="discordref"></span>
              <div id="community">
                <h2> _THE CUBES NETWORK_ </h2>
                
                <a href="https://discord.gg/dYApub3mMf" id="statbutton" className="navlinkexternal" style= {{
                }}>   Club Discord </a> <br /> <br /> 
                
                <WidgetBot
                  server="885603750180446218"
                  channel="885604053353136210"
                  width="300px"
                  height="300px"
                />

                 {/*<br /> <br /><br /> <br /> 4 breaks
                <a href="https://twitter.com/0xdivi?ref_src=twsrc%5Etfw" id="statbutton" className="navlinkexternal" >@0xdivi on twitter </a>  */}
{/*
                <a className="navlinkexternal" href="https://twitter.com/0xdivi?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">follow @0xdivi</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
                 
                <br /><br />  <br /><br />
                For all inquiries contact @0xdivi on twitter. */}
                <br /><br /><br />
                <br /><br /><br /><br />  <br /><br /><br /><br />  <br /><br /><br /><br />  
                </div>
                <span id="diviref"></span>
                Click "Run" to start Divi Support V1  
                {/* <iframe src="https://trinket.io/embed/python/a6992aeda3?outputOnly=true&runOption=run&start=result" width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe> */} 
                <iframe src="https://trinket.io/embed/python/a6992aeda3?outputOnly=true " width="100%" height="600" frameborder="0" marginwidth="0" marginheight="0" allowfullscreen></iframe>

                <br /><br /><br /><br />   
                <br /><br /><br /><br />  <br /><br /><br /><br />  <br /><br /><br /><br />  
                <br /><br /><br /><br />  
              <br /> <br /><br /> <br />  
                <p id="disclaimer"><br /> Disclaimer: <br /> <br />
                 This project involves cryptocurrency, and is connected to a contract deployed on the Ethereum blockchain. <br />
                 Interaction with the functions in this project involves real 'fake internet magic money', <br /> e.g. CRYPTOCURRENCY, tied to real money.  <br /><br />
                 The Creator insists that the User should have an understanding of the potential dangers and high risks of blockchain technology as well as a fair aptitude for interacting with web3. <br/> <br /> 
                 The Creator insists that the User should understand that in interacting with any smart contract technology, errors could result in funds or assets becoming permanently lost. <br/> <br /> 

                 The Creator assumes no responsibility for any at all losses or damages <br /> 
                 caused by the use of this unaudited experimental smart contract. <br /> 
                 The Creator is not a financial advisor. <br />
                 The Creator is not a legal professional.<br />
                 This work is considered final as is. <br /><br /></p> 

            <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
        </div>
     );
}
 
export default About;